export function PopLogoSVG({
  color = "#595FEF",
  ...props
}: {
  color?: string;
} & React.SVGProps<SVGSVGElement>) {
  return <svg viewBox="0 0 96 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="PopLogoSVG" data-sentry-source-file="index.tsx">
      <g id="Pop Navigation Logo" data-sentry-element="g" data-sentry-source-file="index.tsx">
        <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M11.9915 28.8005C22.7838 28.8005 29.1863 22.6692 29.1863 14.6038C29.1863 6.53833 22.6527 0 14.5931 0C6.53358 0 0 6.53833 0 14.6038L0.000328994 36C0.000328994 36 6.5953 28.8005 11.9915 28.8005ZM61.8062 14.6038C61.8062 22.6692 55.2727 29.2075 47.2131 29.2075C39.1535 29.2075 32.6199 22.6692 32.6199 14.6038C32.6199 6.53833 39.1535 0 47.2131 0C55.2727 0 61.8062 6.53833 61.8062 14.6038ZM95.0315 14.6038C95.0315 22.6692 88.629 28.8005 77.8367 28.8005C72.4405 28.8005 65.8456 36 65.8456 36L65.8452 14.6038C65.8452 6.53833 72.3788 0 80.4384 0C88.4979 0 95.0315 6.53833 95.0315 14.6038Z" fill={color} data-sentry-element="path" data-sentry-source-file="index.tsx" />
      </g>
    </svg>;
}