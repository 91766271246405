"use client";

import { BadgePercentIcon, CreditCardIcon, LucideIcon, ReceiptIcon, ClockIcon, ZapIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/components/lib/css";
export function PromoCard({
  color = "#895af0",
  IconComponent,
  title,
  description,
  hideMobile = false,
  sup
}: {
  color?: string;
  IconComponent: LucideIcon;
  title: string;
  description: string;
  hideMobile?: boolean;
  sup?: string;
}) {
  return <div className={`${hideMobile ? "hidden md:flex" : "flex"} box-border w-full max-w-[480px] flex-1 shrink-0 basis-[40%] flex-col items-center justify-start gap-6 self-stretch overflow-hidden rounded-xl bg-white p-6 text-center text-xl font-medium text-txt-secondary lg:basis-[30%]`} data-sentry-component="PromoCard" data-sentry-source-file="promo-terms.tsx">
      <div style={{
      backgroundColor: color
    }} className={`flex flex-row items-center justify-center rounded-[14px] p-3`}>
        <IconComponent size={24} className="text-white" data-sentry-element="IconComponent" data-sentry-source-file="promo-terms.tsx" />
      </div>
      <div className="relative self-stretch">
        <div className="inline-block leading-[125%] [text-shadow:0px_4px_24px_rgba(0,_0,_0,_0.1)]">
          {title}
          {sup ? <sup className="text-xs font-normal text-txt-tertiary"> {sup}</sup> : null}
        </div>
        <div className={"inline-block text-base font-semilight leading-[150%] text-txt-tertiary"}>
          {description}
        </div>
      </div>
    </div>;
}
export function PromoTerms({
  className,
  originationProduct
}: {
  className?: string;
  originationProduct?: boolean;
}) {
  const [showMore, setShowMore] = useState(false);
  return <div className={cn("flex w-full flex-col items-center justify-center bg-surface-dark px-3 py-12 md:rounded-3xl md:px-6", className)} data-sentry-component="PromoTerms" data-sentry-source-file="promo-terms.tsx">
      <div className="flex w-full max-w-[960px] flex-col items-center justify-center gap-6 text-center text-txt-primary md:gap-12">
        <div className="flex flex-col items-center justify-center">
          <div className="flex max-w-[480px] flex-col items-start justify-start gap-[12px] self-stretch">
            <h3 className="leading-[95% relative self-stretch text-4xl font-medium sm:text-5xl">
              Transparent Terms.
              <br />
              No Hidden Fees.
            </h3>
            <div className="relative self-stretch text-base font-semilight leading-[150%] text-txt-secondary sm:text-xl">
              Easy and honest short term financing for small businesses ready to
              go big. Let&rsquo;s break it down.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6 text-xl text-txt-secondary md:grid-cols-3">
          <PromoCard title={originationProduct ? "Fixed Fee" : "Fixed Interest Rate"} sup={originationProduct ? "3" : undefined} description={`A consistent ${originationProduct ? "amount" : "rate"} that provides predictable repayments.`} IconComponent={BadgePercentIcon} color="#6D5DF0" data-sentry-element="PromoCard" data-sentry-source-file="promo-terms.tsx" />
          <PromoCard title="No Hidden Fees" sup={originationProduct ? "3" : undefined} description="Upfront pricing, with no unexpected charges or additional fees." IconComponent={ReceiptIcon} color="#6D5DF0" data-sentry-element="PromoCard" data-sentry-source-file="promo-terms.tsx" />
          <PromoCard title="Apply in Minutes" description="An application that won't keep you away from running your business." IconComponent={ClockIcon} color="#6D5DF0" data-sentry-element="PromoCard" data-sentry-source-file="promo-terms.tsx" />
          <PromoCard title="No Impact on Credit" description="A soft credit check ensures no impact to your credit score." IconComponent={CreditCardIcon} color="#3591E1" hideMobile={!showMore} data-sentry-element="PromoCard" data-sentry-source-file="promo-terms.tsx" />
          <PromoCard title="Fast Delivery of Funds" description="Approved funds deposited in your bank as fast as one business day." IconComponent={ZapIcon} color="#3591E1" hideMobile={!showMore} data-sentry-element="PromoCard" data-sentry-source-file="promo-terms.tsx" />
        </div>
        {!showMore ? <Button onClick={() => setShowMore(!showMore)} variant={"outline"} className="bg-unset mt-6 flex w-full max-w-[480px] border-[2px] border-[hsl(var(--brand))] font-medium text-[hsl(var(--brand))] md:hidden">
            See More
          </Button> : null}
      </div>
    </div>;
}