import { ArrowLeftRightIcon } from "lucide-react";
import React from "react";
import { PopLogoSVG } from "@/components/pop/promotional/svgs";
import { cn } from "@/components/lib/css";
export default function Header(props: React.HTMLAttributes<HTMLDivElement> & {
  PartnerLogo?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>;
}) {
  const {
    className,
    children,
    PartnerLogo,
    ...rest
  } = props;
  return <div className={cn("text-btn-default border-border-light relative box-border flex w-full flex-row items-center justify-center border-b-[1px] border-solid bg-surface-card p-6 text-left text-[13px] md:bg-[unset]", className)} {...rest} data-sentry-component="Header" data-sentry-source-file="header.tsx">
      <div className="flex w-[1392px] max-w-[1440px] flex-1 flex-row items-center justify-between">
        {!PartnerLogo ? <div className="partner-logo h-8 md:h-[42px]">&nbsp;</div> : <div className="flex flex-row items-center justify-start gap-1 sm:gap-2">
            <PopLogoSVG className="h-8 sm:h-9" />
            <ArrowLeftRightIcon className="h-5 text-txt-brand sm:h-6" />
            <PartnerLogo className="h-6 sm:h-7" />
          </div>}
        {children}
      </div>
    </div>;
}